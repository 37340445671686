.nav-link {
  color: black;
}

.nav-link:hover {
  color: #0077b5;
}
.nav-heading {
  color: #1C1F1B;
  display: block;
  font-weight:600;
}
