.skills-header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
}
.skills-header:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 0;
  left: 25%;
  border-bottom: 2px solid red;
}
.logo {
  justify-content: center;
}
