#logo-column {
  justify-content: center;
  text-align: center;
}
.job-desc {
  padding-top: 1.5%;
  font-size: 16px;
  font-weight: 400;
}
.company {
  font-weight: 500;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}
.length {
  float: right;
  font-size: 16px;
  font-style: italic;
}
.job-title {
  font-weight: 600;
}
