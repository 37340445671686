#hero-welcome {
  color: #a44a3f;
  font-size: 24px;
  margin-bottom: 0;
  line-height: 100px;
  font-weight: 600;
}

#hero-title {
  margin-left: -10%;
  font-size: 36px;
  color: #fff;
  background-color: #24232c !important;
  box-shadow:
    -10px -12px 0px #24232c,
    10px -12px 0px #24232c,
    -10px 0px 0px #24232c,
    10px 0px 0px #24232c;
  font-weight: normal;
  display: inline;
  line-height: 0;
}

#banner {
  font-size: 32px;
  color: #fff;
  background-color: #24232c !important;
  box-shadow:
    -10px -12px 0px #24232c,
    10px -12px 0px #24232c,
    -10px 0px 0px #24232c,
    10px 0px 0px #24232c;
  font-weight: normal;
}

#hero-description {
  color: #24232c;
  font-size: 16px;
  font-weight: 400;
  margin: 25px;
}

.hero-icon {
  margin: 20px;
}

#linkedin-icon {
  margin: 10px;
}

.blinking-cursor {
  font-weight: 100;
  font-size: 16px;
  color: #2e3d48;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
