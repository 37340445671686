#linkedin-icon {
  color: #0077b5;
}

#linkedin-icon:hover {
  color: inherit;
}

#github-icon {
  color: inherit;
}

#github-icon:hover {
  color: #0077b5;
}

#experience,
#portfolio {
  background-color: #f2f2f2;
}

.section-title {
  font-size: 150%;
  font-weight: 600;
  color: black;
  margin-bottom: 2%;
}
