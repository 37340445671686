@import url("https://fonts.googleapis.com/css?family=Fugaz+One&display=swap");
@import url("https://fonts.googleapis.com/css?family=Slabo&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fugaz One", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: "Slabo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li {
  font-family: "Slabo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.default-font {
  font-size: 18px;
}

.secondary-font {
  font-size: 16px;
}
